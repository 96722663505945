import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { getColor } from "../../lib/colors"
import { buildImageObj } from "../../lib/helpers"
import { imageUrlFor } from "../../lib/image-url"
import { Box, Button } from "../core"

export function ProductHero({ product }) {
  const {
    title,
    short_description,
    colorPrimary,
    colorSecondary,
    free,
    primary_purchase_location,
    price,
    url_company,
    company_purchase_label,
    url_app_store,
    url_google_play_store,
    slug,
    mainImage,
  } = product

  const GetData = () => {
    const Options = []

    if (primary_purchase_location === "app") {
      if (url_app_store) {
        Options.push({
          label: "Get the iOS App",
          url: url_app_store,
        })
      }

      if (url_google_play_store) {
        Options.push({
          label: "Get the Android App",
          url: url_google_play_store,
        })
      }
    } else {
      switch (free) {
        case "notFree":
          Options.push({
            label: `$${price} on ${company_purchase_label}`,
            url: url_company,
          })
          break

        case "freeWithInsurance":
          Options.push({
            label: `$${price} on ${company_purchase_label} (free with insurance)`,
            url: url_company,
          })
          break

        case "free":
          Options.push({
            label: `Free on ${company_purchase_label}`,
            url: url_company,
          })
      }
    }

    return Options
  }

  return (
    <HeroWrapper>
      <TextWrapper
        desktopBackgroundColor={getColor(colorSecondary).background}
        desktopColor={getColor(colorSecondary).foreground}
      >
        <Title>{title}</Title>
        <Subtitle>{short_description}</Subtitle>
        <Box display={["unset", "unset", "flex"]}>
          {GetData().map((option) => (
            <GetButton key={option.label} href={option.url} size="medium">
              {option.label}
            </GetButton>
          ))}
        </Box>
      </TextWrapper>
      <ImageWrapper backgroundColor={getColor(colorPrimary).background}>
        {mainImage && mainImage.asset && (
          <img
            style={{ width: "80%", height: "auto", zIndex: 1 }}
            src={imageUrlFor(buildImageObj(mainImage)).width(400).height(400).fit("max").ignoreImageParams().url()}
          />
        )}
      </ImageWrapper>
    </HeroWrapper>
  )
}

const HeroWrapper = styled(Box).attrs(() => ({}))`
  position: relative;

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    border: 2px solid ${themeGet("colors.black")};
    display: grid;
    grid-template-columns: 60% 40%;
    min-height: 440px;
    direction: rtl;

    & > * {
      direction: ltr;
    }
  }
`

const ImageWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${themeGet("breakpoints.sm")}) {
    border: 2px solid ${themeGet("colors.black")};
  }
  @media (min-width: ${themeGet("breakpoints.sm")}) {
    border-right: 2px solid ${themeGet("colors.black")};
  }
`

const TextWrapper = styled(Box).attrs(() => ({
  padding: ["0", "64px"],
  mb: ["40px", "0"],
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: ${themeGet("breakpoints.sm")}) {
    background-color: ${(props) => props.desktopBackgroundColor};
    color: ${(props) => props.desktopColor};
  }
`

const Title = styled(Box).attrs(() => ({
  as: "h1",
  mb: ["8px", "16px"],
  fontSize: [7, 7],
  fontWeight: 2,
  lineHeight: 0,
}))``

const Subtitle = styled(Box).attrs(() => ({
  as: "h2",
  fontSize: [3, 4],
  fontWeight: 0,
  lineHeight: 2,
}))``

const GetButton = styled(Button).attrs(() => ({
  as: "a",
  mt: [5],
  mr: [2],
  fontSize: [2, 2, 3],
}))`
  transition: all 100ms ease-in-out;
  &,
  &:hover {
    color: inherit;
    border: 2px solid;
    background-color: transparent;
  }

  &:hover {
    opacity: 0.8;
  }
`
